import { request } from "@/request/http";

export default {
  getCategoryList: (data) => {
    return request("GET", `/index.php/center/getCategoryList`, data);
  },

  getFolderInfo: (data) => {
    return request("POST", `/index.php/center/getFolderInfo`, data);
  },

  delPages: (data) => {
    return request("GET", `/index.php/center/delPages`, data);
  },

  delFolder: (data) => {
    return request("POST", `/index.php/center/delFolder`, data);
  },

  updateFolder: (data) => {
    return request("GET", `/index.php/center/updateFolder`, data);
  },

  updatePages: (data) => {
    return request("POST", `/index.php/center/updatePages`, data);
  },

  getNoticeInfo: (data) => {
    return request("GET", `/index.php/center/getNoticeInfo`, data);
  },

  updateSortCenterFolder: (data) => {
    return request("POST", `/index.php/center/updateSortCenterFolder`, data);
  },

  updateSortCenterPages: (data) => {
    return request("POST", `/index.php/center/updateSortCenterPages`, data);
  },

  getFolderList: (data) => {
    return request("POST", `/index.php/center/getFolderList`, data);
  },
}

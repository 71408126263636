<template>
    <!-- 素材列表页 -->
    <div class="pdf">
        <!-- 搜索框 -->
        <div class="div-height div">
            <div class="left">
                <el-button
                    type="primary"
                    @click="handleOpen('is_add', '添加页面')"
                    >添加页面</el-button
                >
                <el-button @click="handleOpen('is_add_category', '添加栏目')"
                    >添加栏目</el-button
                >
            </div>
        </div>
        <!-- 表格 -->
        <div class="div">
            <!-- default-expand-all 树形图打开属性 -->
            <el-table
                :data="tableData"
                style="width: 100%;margin-bottom: 20px;"
                row-key="id"
                border
                default-expand-all
                :tree-props="{
                    children: 'children',
                    hasChildren: 'hasChildren'
                }"
            >
                <!-- 排序 -->
                <el-table-column label="排序" sortable >
                    <template slot-scope="scope">
                        <span>
                            <el-input
                                v-model="scope.row.sort_no"
                                @blur="sortNoUpdate(scope.row)"
                                style="width:80px"
                            ></el-input>
                        </span>
                    </template>
                </el-table-column>
                <!-- 名称 -->
                <el-table-column prop="title" label="名称" sortable>
                </el-table-column>
                <!-- 类型 -->
                <el-table-column label="类型" align="center">
                    <template slot-scope="scope">
                        <span v-if="!('is_folder' in scope.row)">素材</span>
                        <span v-else>目录</span>
                    </template>
                </el-table-column>
                <!-- 操作 -->
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <span v-if="!('is_folder' in scope.row)">
                            <el-button
                                size="mini"
                                type="primary" plain
                                @click="
                                    handleOpen(
                                        'is_delete',
                                        '删除素材',
                                        scope.$index,
                                        scope.row
                                    )
                                "
                                >删 除</el-button
                            >
                            <el-button
                                size="mini"
                                type="primary" plain
                                @click="
                                    handleOpen(
                                        'is_update',
                                        '编辑素材',
                                        scope.$index,
                                        scope.row
                                    )
                                "
                                >编 辑</el-button
                            >
                        </span>
                        <span v-else>
                            <el-button
                                size="mini"
                                type="primary" plain
                                @click="
                                    handleOpen(
                                        'is_delete_category',
                                        '删除目录',
                                        scope.$index,
                                        scope.row
                                    )
                                "
                                >删 除</el-button
                            >
                            <el-button
                                size="mini"
                                type="primary" plain
                                @click="
                                    handleOpen(
                                        'is_update_category',
                                        '编辑目录',
                                        scope.$index,
                                        scope.row
                                    )
                                "
                                >编 辑</el-button
                            >
                        </span>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <span>
                <span
                    v-if="
                        handleSign != 'is_delete' &&
                            handleSign != 'is_delete_category' &&
                            handleSign != 'is_add'
                    "
                >
                    <el-input
                        v-model="categoryName"
                        placeholder="请输入栏目名称"
                    ></el-input>
                </span>
                
                <span v-else-if="handleSign == 'is_add'">
                    <p class="p-add">页面标题</p>
                    <el-input
                        v-model="categoryName"
                        placeholder="请输入页面标题"
                    ></el-input>
                    <br />
                    <br />
                    <p class="p-add">所属栏目</p>
                    <el-select
                        v-model="folderid"
                        placeholder="请选择所属栏目"
                        class="input-width"
                        style="width:100%"
                    >
                        <el-option
                            v-for="item in categoryList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </span>
                <span v-else class="dialog-span">您确定要删除 "{{categoryName}}" 么？</span>
            </span>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogFix()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style lang="scss" scoped>
@import "@/assets/css/teacher/center/webPageContent.scss";
</style>

<script>
import webPageContent from "@/assets/js/teacher/center/webPageContent.js";
export default {
    ...webPageContent
};
</script>

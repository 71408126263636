import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入

import { sleep, startLoading, endLoading } from "@/utils/function"; 
import API from '@/api/teacher/center/webPageContent';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "other",
    components: {},
    data() {
        return {
            // 当前页码
            page: 1,
            // 表格数据
            tableData: [
                {
                    id: 149,
                    title: "醇系物(大类6学时)",
                    time: "2019-03-23 09:12:32",
                    folderid: 18
                },
                {
                    id: 166,
                    title: "阿司匹林-液相(4学时)",
                    time: "2019-04-07 15:30:25",
                    folderid: 18
                }
            ],
            // dialog 显示标识
            dialogVisible: false,
            // 页面id
            pages_id: "",
            // 操作标识
            handleSign: "",
            // 添加分类名称
            categoryName: "",
            // 弹窗标题
            dialogTitle: "",
            // 排序
            sort_no: 0,
            // 二维码链接
            qrcode: "",
            // 文章标题
            articleTitle: "",
            // 分类列表
            categoryList: "",
            //  添加文章分类id
            folderid: ""
        };
    },
    created() {
        const self = this;
        self.getData();
    },
    methods: {
        /**
         * @name: 获取分类列表
         * @author: camellia
         * @date: 2021-01-15 11:04:54
         */
        getCategoryList() {
            const self = this;
            let data = {};
            // 请求数据
            API.getCategoryList(data)
                .then(result => {
                    // console.log(result);
                    self.categoryList = result.categoryList;
                })
                .catch(err => {});
            // self.axios
            //     .get("/index.php/center/getCategoryList", {
            //         params: data
            //     })
            //     .then(result => {
            //         // console.log(result);
            //         self.categoryList = result.data.categoryList;
            //     })
            //     .catch(err => {});
        },
        
        /**
         * @name: 删除/修改/添加弹窗
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        handleOpen(sign, title = "", index = "", row = "") 
        {
            const self = this;
            self.handleSign = sign;
            self.dialogTitle = title;

            if (sign == "is_delete") 
            {// 删除内容
                self.dialogVisible = true;
                self.categoryName = row.title;
                self.pages_id = row.id;
            } 
            else if (sign == "is_delete_category") 
            {// 删除分类
                self.dialogVisible = true;
                self.categoryName = row.name;
                self.category_id = row.id;
            } 
            else if (
                sign == "is_add_category" ||
                sign == "is_update_category"
            ) 
            {// 添加修改分类
                self.categoryName = '';
                self.category_id = "";
                if (sign == "is_update_category") 
                {
                    self.category_id = row.id;
                    self.getCategoryInfo();
                }
                self.dialogVisible = true;
            } 
            else if (sign == "is_add") 
            {// 添加页面
                self.categoryName = '';
                self.dialogVisible = true;
                self.getCategoryList();
            } 
            else if (sign == "is_update") 
            {// 修改页面
                self.pages_id = row.id;
                self.$router.push({
                    path: "/teacher/center/webPageUpdate",
                    query: {
                        pages_id: self.pages_id
                    }
                });
            }
        },

        /**
         * @name: 获取目录信息
         * @author: camellia
         * @date: 2021-01-14 10:22:19
         */
        getCategoryInfo() {
            const self = this;
            let data = {
                category_id: self.category_id
            };
            // 请求数据
            API.getFolderInfo(data)
                .then(result => {
                    self.categoryName = result.info.name;
                })
                .catch(err => {});  
            // self.axios
            //     .get("/index.php/center/getFolderInfo", {
            //         params: data
            //     })
            //     .then(result => {
            //         self.categoryName = result.data.info.name;
            //     })
            //     .catch(err => {});
        },

        /**
         * @name: 弹窗确定键点击事件
         * @author: camellia
         * @date: 2021-01-11 10:25:50
         */
        dialogFix() 
        {
            const self = this;
            let data = {
                pages_id: self.pages_id,
                categoryName: self.categoryName,
                category_id: self.category_id
            };

            if (self.handleSign == "is_delete") 
            {
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.delPages(data)
                    .then(result => {
                        self.getData();
                        self.dialogVisible = false;
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                        this.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'success',
                            displayTime:1500
                        })
                    })
                    .catch(err => {
                        nprogressClose();
                        self.dialogVisible = false;
                    });
            } 
            else if (self.handleSign == "is_delete_category") 
            {
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.delFolder(data)
                    .then(result => {
                        self.getData();
                        self.dialogVisible = false;
                        nprogressClose();
                        self.$Loading.hide();
                        this.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'success',
                            displayTime:1500
                        })
                    })
                    .catch(err => {
                        self.dialogVisible = false;
                    });
            } 
            else if (
                self.handleSign == "is_update_category" ||
                self.handleSign == "is_add_category"
            ) 
            {
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.updateFolder(data)
                .then(result => {
                    self.getData();
                    self.dialogVisible = false;
                    self.categoryName = "";
                    nprogressClose();
                    self.$Loading.hide();
                    this.$Tips({
                        // 消息提示内容
                        message:result.msg,
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'success',
                        displayTime:1500
                    })
                })
                .catch(err => {});
            } 
            else if (self.handleSign == "is_add") 
            {
                let data = {
                    folderid: self.folderid,
                    title: self.categoryName
                };
                if (self.categoryName == "") 
                {
                    // Message.warning("请填写素材标题");
                    this.$Tips({
                        // 消息提示内容
                        message:"请填写素材标题",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                    return;
                } 
                else if (self.folderid == "") 
                {
                    // Message.warning("请选择素材分类");
                    this.$Tips({
                        // 消息提示内容
                        message:"请选择素材分类",
                        // 消息提示类型（success-成功,warning-警告/失败）
                        messageType:'warning',
                        button:[
                            {
                                type:1,
                                text:'知道了'
                            }
                        ]
                    })
                    return;
                }
                // 开启loading
                nprogressStart();
                self.$Loading.show();
                // 请求数据
                API.updatePages(data)
                    .then(result => {
                        // 关闭loading
                        nprogressClose();
                        self.$Loading.hide();
                        self.pages_id = result.data.id;
                        self.$router.push({
                            path: "/teacher/center/webPageUpdate",
                            query: {
                                pages_id: self.pages_id
                            }
                        });
                    })
                    .catch(err => {
                        nprogressClose();
                        self.dialogVisible = false;
                    });
            }
        },
        /**
         * @name:修改排序
         * @author: camellia
         * @date: 2021-01-15 10:07:15
         */
        sortNoUpdate(param) {
            const self = this;
            let url;
            if ("is_folder" in param) 
            {
                url = "/index.php/center/updateSortCenterFolder";
                let data = {
                    id: param.id,
                    sort_no: param.sort_no
                };
                // 请求数据
                API.updateSortCenterFolder(data)
                    .then(result => {
                        self.getData();
                    })
                    .catch(err => {});
            } 
            else 
            {
                url = "/index.php/center/updateSortCenterPages";
                let data = {
                    id: param.id,
                    sort_no: param.sort_no
                };
                // 请求数据
                API.updateSortCenterPages(data)
                    .then(result => {
                        self.getData();
                    })
                    .catch(err => {});
            }
        },

        /**
         * @name: 获取显示数据
         * @author: camellia
         * @date: 2020-12-29 17:32:19
         */
        getData() {
            const self = this;
            let data = {
                page: self.page,
            };
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 请求数据
            API.getFolderList(data)
                .then(result => {
                    self.tableData = result.folderList;
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch(err => {});
        }
    }
};
